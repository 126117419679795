// Generated by Framer (e1877f1)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Image, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Menu from "./uQpx9Hu0A";
const MenuFonts = getFonts(Menu);

const cycleOrder = ["B37HrnbDL", "ot3jw09Sv"];

const serializationHash = "framer-kOdDP"

const variantClassNames = {B37HrnbDL: "framer-v-wgl5nf", ot3jw09Sv: "framer-v-vdb7x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "B37HrnbDL", "Variant 2": "ot3jw09Sv"}

const getProps = ({height, id, logo, tap, width, ...props}) => { return {...props, clwRJvmLC: logo ?? props.clwRJvmLC ?? {src: "https://framerusercontent.com/images/FckyL3tMi0QMkjvlQZRbiGVnrE.png"}, Fv5UOj18q: tap ?? props.Fv5UOj18q, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "B37HrnbDL"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;logo?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Fv5UOj18q, clwRJvmLC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "B37HrnbDL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const m0uxnhUKB19sl689 = activeVariantCallback(async (...args) => {
if (Fv5UOj18q) {const res = await Fv5UOj18q(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wgl5nf", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"B37HrnbDL"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ot3jw09Sv: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Link href={{webPageId: "ueiKkVaj3"}}><motion.a className={"framer-ztgdum framer-jmqyjz"} layoutDependency={layoutDependency} layoutId={"QUhGxRnLh"}><Image background={{alt: "", fit: "fit", sizes: "97px", ...toResponsiveImage(clwRJvmLC), ...{ positionX: "center", positionY: "center" }}} className={"framer-12n0ibb"} data-framer-name={"Photographer Icon"} layoutDependency={layoutDependency} layoutId={"uLxldlPGc"}/></motion.a></Link><ComponentViewportProvider width={"40px"}><motion.div className={"framer-14pyvng-container"} layoutDependency={layoutDependency} layoutId={"CTbMutBhh-container"}><Menu height={"100%"} id={"CTbMutBhh"} layoutId={"CTbMutBhh"} m0uxnhUKB={m0uxnhUKB19sl689} style={{height: "100%", width: "100%"}} variant={"aP2TFpTQ4"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.nav></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kOdDP.framer-jmqyjz, .framer-kOdDP .framer-jmqyjz { display: block; }", ".framer-kOdDP.framer-wgl5nf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; height: 80px; justify-content: space-between; overflow: hidden; padding: 0px 40px 0px 40px; position: relative; width: 1200px; }", ".framer-kOdDP .framer-ztgdum { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 25px; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 97px; }", ".framer-kOdDP .framer-12n0ibb { flex: none; height: 25px; overflow: visible; position: relative; width: 97px; }", ".framer-kOdDP .framer-14pyvng-container { flex: none; height: 40px; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kOdDP .framer-ztgdum { gap: 0px; } .framer-kOdDP .framer-ztgdum > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-kOdDP .framer-ztgdum > :first-child { margin-left: 0px; } .framer-kOdDP .framer-ztgdum > :last-child { margin-right: 0px; } }", ".framer-kOdDP.framer-v-vdb7x.framer-wgl5nf { padding: 0px 12px 0px 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ot3jw09Sv":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Fv5UOj18q":"tap","clwRJvmLC":"logo"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUEn7U9jTS: React.ComponentType<Props> = withCSS(Component, css, "framer-kOdDP") as typeof Component;
export default FramerUEn7U9jTS;

FramerUEn7U9jTS.displayName = "Nav";

FramerUEn7U9jTS.defaultProps = {height: 80, width: 1200};

addPropertyControls(FramerUEn7U9jTS, {variant: {options: ["B37HrnbDL", "ot3jw09Sv"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, Fv5UOj18q: {title: "Tap", type: ControlType.EventHandler}, clwRJvmLC: {__defaultAssetReference: "data:framer/asset-reference,FckyL3tMi0QMkjvlQZRbiGVnrE.png?originalFilename=Finnegan.png&preferredSize=auto", title: "Logo", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerUEn7U9jTS, [{explicitInter: true, fonts: []}, ...MenuFonts], {supportsExplicitInterCodegen: true})